import { useState } from "react";

// resources
import expand2 from "../../assets/img/svg/expand_2.svg";
import close from "../../assets/img/svg/close.svg";

// styles
import "../../assets/css/components/full_screen.css";

const FullScreen = ({ type, setIsFullKanban }) => {
  const
    [openFull, setOpenFull] = useState(false)
  ;

  const fullAction = (e) => {
    e.preventDefault();

    const container_btn = e.target.parentElement;

    setOpenFull(!openFull);

    if (!openFull) { // Open
      document.querySelector(".container_page").classList.add("full");
      document.querySelector(".user_menu").classList.add("hide");
      document.body.classList.add("no_scroll");
      container_btn.classList.add("active");
    } else { // Close
      document.querySelector(".container_page").classList.remove("full");
      document.querySelector(".user_menu").classList.remove("hide");
      document.body.classList.remove("no_scroll");
      container_btn.classList.remove("active");
    }

    switch (type) {
      case "WHS":
        if (!openFull) {
          document.querySelector(".container_gantt").classList.add("full");
        } else {
          document.querySelector(".container_gantt").classList.remove("full");
        }
      break;
      case "SLA":
        setIsFullKanban(!openFull);
        if (!openFull) {
          document.querySelector(".container_kanban").classList.add("full");
          document.querySelector(".container_kanban").classList.remove("item_container_sla");
        } else {
          document.querySelector(".container_kanban").classList.remove("full");
          document.querySelector(".container_kanban").classList.add("item_container_sla");
        }
      break;
      case "special":
        setIsFullKanban(!openFull);
        if (!openFull) {
          document.querySelector(".sla_specialist").classList.add("full");
          document.querySelector(".sla_specialist").classList.remove("item_container_sla");
        } else {
          document.querySelector(".sla_specialist").classList.remove("full");
          document.querySelector(".sla_specialist").classList.add("item_container_sla");
        }
        break;

      case "example":
        setIsFullKanban(!openFull);
        if (!openFull) {
          document.querySelector(".data_snapshot").classList.add("full");
          document.querySelector(".data_snapshot").classList.remove("item_container_sla");
        } else {
          document.querySelector(".data_snapshot").classList.remove("full");
          document.querySelector(".data_snapshot").classList.add("item_container_sla");
        }
        break;
      default:
        console.log("No se envió el type al component FullScreen");
      break;
    }
  }

  return (
    <div className="container_fullscreen_gk">
      <button
        className="fullscreen_btn"
        onClick={fullAction}
      >
        <img
          className={`icon_expand_2 ${openFull ? "hide" : ""}`}
          src={expand2}
          alt="Full Screen"
        />
        <img
          className={`icon_close ${openFull ? "" : "hide"}`}
          src={close}
          alt="Full Screen"
        />
      </button>
    </div>
  );
}

export default FullScreen;
