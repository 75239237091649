import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

import "../../assets/css/components/loading.css";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return (
      <div className="loading">
        <div className="bouncing_loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
