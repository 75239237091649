import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// components
import GetLink from "../../components/GetLink/GetLink.js";
import { fetchData } from "../../services/apiService.js";

// styles
import "../../assets/css/components/segments.css";

const Segments = ({ onLinkClick, setIsSpecial = null }) => {
  const [segments, setSegments] = useState([]);

  // Función para renderizar el botón del segmento
  const renderButtonSegment = (segment) => (
    <p className="text">{segment.title}</p>
  );

  const fetchSegments = async () => {
    try {
      const id_segment = sessionStorage.getItem("season_now") ?? "";
      const response = await fetchData(`/api/list/segments/${id_segment}`);
      // console.dir(response.data);
      setSegments(response.data);
    } catch (error) {
      console.error("Error fetching Segments:", error);
    }
  };

  console.dir(setIsSpecial);

  useEffect(() => {
    fetchSegments();
  }, []);

  return (
    <div className="segments_container">
      <h2 className="segments_title">Explora más segmentos</h2>
      <div className="segments_list">
        {segments.map((segment) => {
          const linkTo = GetLink({ segments: segment });
          return (
            <div key={segment.id} className="segment_button">
              {linkTo ? (
                <Link
                  to={linkTo}
                  className="link_segment"
                  onClick={() => {
                    if (onLinkClick) onLinkClick(segment.title)
                    if (setIsSpecial) setIsSpecial(false);
                  }}
                >
                  {renderButtonSegment(segment)}
                </Link>
              ) : (
                renderButtonSegment(segment)
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Segments;

