export const fetchData = async (endpoint) => {
  const API_URL = process.env.REACT_APP_API_URL + endpoint;
  const apiKey = process.env.REACT_APP_API_KEY;

  const myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const response = await fetch(API_URL, requestOptions);
    const result = await response.json();

    // Procesar si es 404 pero la data es válida
    if (!response.ok) {
      if (response.status === 404 && result) {
        console.warn("404 recibido, pero con datos disponibles:", result);
        return result;
      }
      throw new Error("Network response was not ok");
    }

    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

