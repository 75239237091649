import { useState, useEffect } from "react";

import error_icon from "../../assets/img/svg/error.svg";
import "../../assets/css/components/message.css";

const Message = ({ message, type, title }) => {
  const
    [visible, setVisible] = useState(true),
    [stateMessage, setStateMessage] = useState("")
  ;

  useEffect(() => {
    setVisible(true);
    setStateMessage("");

    const openTime = setTimeout(() => {
      setStateMessage("open");
    }, 500);

    const timer = setTimeout(() => {
      setVisible(false);
    }, 5500);

    const closeTime = setTimeout(() => {
      setStateMessage("close");
    }, 5000);

    return () => {
      clearTimeout(timer);
      clearTimeout(closeTime);
      clearTimeout(openTime);
    }
  }, [message]);

  if (!visible) return null;

  return (
    <div className={`message ${type} ${stateMessage}`}>
      <img className="icon_message" src={error_icon} alt="error" />
      <h2 className="title_message">
        {title}
      </h2>
      <p className="content_message">
        {message}
      </p>
    </div>
  );
};

export default Message;

