export const requestChangePassword = async (uid, csrfToken, tempPassword, newPassword) => {
  const API_URL = process.env.REACT_APP_API_URL ;

  try {
    const response = await fetch(`${API_URL}/user/${uid}?_format=json`, {
        method: "PATCH",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          pass: [
            { existing: tempPassword, value: newPassword }
          ]
        })
    });

    if (!response.ok) {
      throw new Error("Failed to send password recovery request");
    }

    return await response.json();
  } catch (error) {
    console.error("Error during password recovery:", error);
    throw error;
  }
};

