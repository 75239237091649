import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// components
import NavigationBar from "../../components/NavigationBar/NavigationBar.js"
import Gantt from "../../components/Gantt/Gantt.js";
import FullScreen from "../../components/FullScreen/FullScreen.js";
import Segments from "../../components/Segments/Segments.js";
import ButtonBack from "../../components/ButtonBack/ButtonBack.js";
import { FilterSeason } from "../../components/FilterSeason/FilterSeason.js";
import { MenuUser } from "../../components/MenuUser/MenuUser.js";
import { FilterCategory } from "../../components/FilterCategory/FilterCategory.js";
import { FilterProvider } from "../../components/FilterCategory/FilterContext.js";

// resources
import logo_adidas_white from "../../assets/img/svg/adidas_white.svg";

// styles
import "../../assets/css/pages/whs.css";

const WHS = () => {
  const
    [categoryFilterSelected, setCategoryFilterSelected] = useState(null),
    [seasonFilterSelected, setSeasonFilterSelected] = useState(null),
    [historyActivityID, setHistoryActivityID] = useState(new Set()),
    [resetHistory, setResetHistory] = useState(false)
  ;

  const
    params = useParams()
  ;

  const handleChangeCategory = (idCategory) => {
    setCategoryFilterSelected(idCategory);
  }

  const handleChangeSeason = (idSeason) => {
    setSeasonFilterSelected(idSeason);
  }

  const handleLinkClick = (title = "") => {
    setResetHistory(true);
    window.scrollTo({
      behavior: "smooth",
      top: 0
    });
    sessionStorage.setItem("segment_title_now", title);
  };

  useEffect(() => {
    if (resetHistory) {
      setHistoryActivityID(new Set());
      setResetHistory(false);
    }
  }, [resetHistory]);

  return (
    <div className="container_whs layout_1_j">
      <NavigationBar className="j_1" styleType={"close"} />
      <MenuUser/>
      <ButtonBack />

      <div className="container_page j_2">
        <picture className="single_logo_adidas">
          <img src={logo_adidas_white} alt="adidas" className="img" />
        </picture>

        <h1 className="title_whs">
          OMNI LAM SEASONAL ACTIVATION PLAN
        </h1>
        <h2 className="subtitle_whs no_visible">
          OMNI LAM SEASONAL ACTIVATION PLAN
        </h2>
        <h3 className="subtitle2_whs">
          {sessionStorage.getItem("segment_title_now")}
        </h3>
        <p className="desc_whs">
          Puedes filtrar tu búsqueda con los siguientes tags de categorías.
        </p>

        <FilterProvider>
          <div className="content_filters">
            <div className="title_find">
              Filtrar por
            </div>
            <div className="layout_filters">
              <FilterSeason onSeasonSelected={handleChangeSeason}/>
                <FilterCategory
                  onCategorySelected={handleChangeCategory}
                  onSeasonSelected={handleChangeSeason}
                />
              <FullScreen type="WHS"/>
            </div>
          </div>

          <Gantt
            params={params}
            categorySelected={categoryFilterSelected}
            seasonSelected={seasonFilterSelected}
            historyActivityID={historyActivityID}
            setHistoryActivityID={setHistoryActivityID}
            onCategorySelected={handleChangeCategory}
          />
        </FilterProvider>

        <Segments onLinkClick={handleLinkClick} />
      </div>
    </div>
  );
};

export default WHS;
