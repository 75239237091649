export const authenticateUser = async (username, password) => {
  const API_URL = process.env.REACT_APP_API_URL;

  /* NOTE: Ver datos para el logueo
    console.log(process.env);
    console.log(API_URL);
    console.log(username, password);
    console.log(JSON.stringify({ name: username, pass: password }));
  */

  try {
    const response = await fetch(`${API_URL}/user/login?_format=json`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ name: username, pass: password }),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Invalid credentials");
    }
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
};
