import { Link } from "react-router-dom";

// components
import GetLink from "../../components/GetLink/GetLink.js";

// styles
import "../../assets/css/components/card_holding.css";

// resources
import arrow from "../../assets/img/svg/arrow.svg";

export const CardHolding = ({ cardType = "", segments }) => {
  const renderCardContent = () => (
    <div className={`card_holding ${cardType}`}>
      <p className="subtitle_card">{segments.placeholder}</p>
      <h1 className="title_card">{segments.title}</h1>
      <div className="content_img_card">
        <img src={arrow} alt="arrow" className="img" />
      </div>
    </div>
  );

  const linkTo = GetLink({ segments });

  const handleLinkClick = () => {
    sessionStorage.setItem("segment_title_now", segments.title);
  };

  return (
    <>
      {linkTo ? (
        <Link to={linkTo} className="link_holding" onClick={handleLinkClick}>
          {renderCardContent()}
        </Link>
      ) : (
        renderCardContent()
      )}
    </>
  );
};
