import { useState, useCallback, useEffect } from "react";

// components
import { fetchData } from "../../services/apiService";

// styles
import "../../assets/css/components/season_holding.css";

const SeasonHolding = ({ onSeasonChange, setActiveButton, activeButton }) => {
  const
    [showContent, setShowContent] = useState(false),
    [seasons, setSeasons] = useState([])
  ;

  const url_season = "/api/list/season-modules";

  const fetchSeason = useCallback(async () => {
    try {
      const response = await fetchData(url_season);
      // console.dir(response.data);
      setSeasons(response.data);

      const
        defaultSeason = response.data.find((season) => season.default === true),
        seasonNow = sessionStorage.getItem("season_now"),
        activeSeasonId = seasonNow || (defaultSeason ? defaultSeason.id : null)
      ;

      sessionStorage.setItem("season_now", activeSeasonId);
      sessionStorage.setItem("season_default", defaultSeason.id);

      setActiveButton(seasonNow);
      onSeasonChange(seasonNow);
    } catch (error) {
      console.error("Error fetching Seasons:", error);
    }
  }, [onSeasonChange, setActiveButton]);

  const handleClick = (id) => {
    sessionStorage.setItem("season_now", id);
    setActiveButton(id);
    onSeasonChange(id);
  };

  useEffect(() => {
    fetchSeason();
  }, [fetchSeason]);

  useEffect(() => {
    if (seasons.length > 0) {
      const timer = setTimeout(() => {
        setShowContent(true);
      }, 50);

      return () => clearTimeout(timer);
    }
  }, [seasons]);

  if (!showContent) return null;

  return (
    <div className="container_season">
      <h4 className="title">Temporada</h4>
      <div className="container_btns">
        {seasons.map((button) => (
          <div key={button.id} style={{ position: "relative" }}>
            <button
              className={`btn_season ${
                activeButton === button.id ? "active" : ""
              }`}
              onClick={() => handleClick(button.id)}
            >
              {button.title}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SeasonHolding;

