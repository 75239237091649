import React from "react";

// styles
import "../../assets/css/components/modal.css";

// resources
import img_close from "../../assets/img/svg/close.svg";

const Modal = ({ image, onClose }) => {
  if (!image) return null;
  return (
    <div className="modal">
      <div className="modal-content">
        <button className="btn_close_full close" id="btn_close_full" onClick={onClose}>
          <img src={img_close} alt="X" className="img_close"/>
        </button>
        <img src={image} alt="Expanded" className="img_modal"/>
      </div>
    </div>
  );
};

export default Modal;


