import { useState } from "react";

// components
import { NormalizeCategory } from "../../components/FilterCategory/FilterCategory.js";

// resources
import download from "../../assets/img/svg/download.svg";
import expand from "../../assets/img/svg/expand.svg";
import next_img from "../../assets/img/svg/arrow_img_next.svg";
import prev_img from "../../assets/img/svg/arrow_img_prev.svg";

// styles
import "../../assets/css/components/card_tools.css";

const CardTools = ({
  card,
  setOnExpand,
  setIsModalOpen,
  index,
  totalCards,
  currentCardIndex
  }) => {

  const title = card && card.title ? card.title : "";

  const
    [currentImageIndex, setCurrentImageIndex] = useState(0)
  ;

  const handleNextImage = (e) => {
    const parentContainer = e.target.closest(".card_container_tools");
    const imagePlaceholders = parentContainer.querySelectorAll(".image_placeholder");

    setCurrentImageIndex((prevIndex) =>
      prevIndex === card.images.length - 1 ? 0 : prevIndex + 1
    );

    imagePlaceholders.forEach((placeholder) => {
      const imgElement = placeholder.querySelector(".image");
      imgElement.src = card.images[currentImageIndex].url;
    });
  };

  const handlePrevImage = (e) => {
    const parentContainer = e.target.closest(".card_container_tools");
    const imagePlaceholders = parentContainer.querySelectorAll(".image_placeholder");

    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? card.images.length - 1 : prevIndex - 1
    );

    imagePlaceholders.forEach((placeholder) => {
      const imgElement = placeholder.querySelector(".image");
      imgElement.src = card.images[currentImageIndex].url;
    });
  };

  const openToolkit = () => {
    console.dir(card);
    if (card.link_toolkit !== "") window.open(card.link_toolkit, "_black");
  }

  const handleOnExpand = (img) => {
    setOnExpand(img);
    setIsModalOpen(true);
    document.querySelector(".user_menu").classList.add("hide");
  }

  const selectTypeCard = () => {
    if (card) {
      if (card.images.length > 0) {
        return (
          <>
            <div className="image_container">
              <picture className="image_placeholder first_image">
                {card.images[currentImageIndex]?.url && (
                  <img src={card.images[currentImageIndex].url} alt={title} className="image"/>
                )}
              </picture>

              <button
                className="control_button"
                onClick={() => handleOnExpand(card.images[currentImageIndex]?.url)} // Pasa la imagen actual
              >
                <img src={expand} alt="Expand" className="image_expand"/>
              </button>

              <button
                className={`download_button ${card.toolkit !== "" ? "" : "no_visible"}`}
                onClick={openToolkit}
              >
                <img src={download} alt={expand} className="download_toolkit"/>
              </button>
            </div>

            <div className={`slider_controls ${card.images.length <= 1 ? "no_visible" : ""}`}>
              <button className="prev_button" onClick={handlePrevImage}>
                <img src={prev_img} alt="Prev" />
              </button>
              <button className="next_button" onClick={handleNextImage}>
                <img src={next_img} alt="Next" />
              </button>
            </div>
          </>
        );
      } else {
        return (
          <div className="text_container" dangerouslySetInnerHTML={{ __html: card.description_list }} />
        );
      }
    } else {
        return (<></>);
    }
  }

  const getCategories = () => {
    if (card) {
      return (
        <div className="categories_container">
        {card.categories.map((category, index) => (
          <div
          key={index}
          className={`category_inside ${NormalizeCategory(category.name)} cat_${category.id}`}
          style={{backgroundColor: category.color}}
          />
        ))}
        </div>
      );
    } else {
      return (<></>);
    }
  }

  return (
    <div className={`card_container_tools ${currentCardIndex === index ? "" : "hide"}`}>

      { getCategories() }
      { selectTypeCard() }

      <div className="title">{title}</div>
    </div>
  );
};

export default CardTools;
