export const requestPasswordRecovery = async (email) => {
  const API_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await fetch(`${API_URL}/user/lost-password?_format=json`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ mail: email })
    });
    if (!response.ok) {
      throw new Error("Failed to send password recovery request");
    }
    return await response.json();
  } catch (error) {
    console.error("Error during password recovery:", error);
    throw error;
  }
};

