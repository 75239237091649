import { useEffect, useState, useContext } from "react";

// components
import { fetchData } from "../../services/apiService.js";
import { areSetsEqual } from "../../utils/areSetsEqual.js";
import { FilterContext } from "./FilterContext.js";

// styles
import "../../assets/css/components/filter_season.css";

const url_categories = "/api/list/categories";
let prevCategories = new Set();
let count = 0;

const HideCategory = (existingCategories) => {
  const
    btns = document.querySelectorAll(".filter_buttons .filter_button"),
    btn_filter = document.querySelector(".filter_buttons"),
    isEqual = areSetsEqual(prevCategories, existingCategories)
  ;

  count = 0;

  if (!isEqual) {
    prevCategories = existingCategories;
  }

  for (const btn of btns) {
    btn.classList.remove("visible");
  }

  for (const category of existingCategories) {
    for (const btn of btns) {
      if (btn.classList.contains(category)) {
        btn.classList.add("visible");
        count++;
      }
    }
  }

  if (btn_filter && count === 0) {
    btn_filter.classList.add("no_visible");
  } else if (btn_filter) {
    btn_filter.classList.remove("no_visible");
  }
}

const NormalizeCategory = (input) => {
  let normalized = input.toLowerCase();
  normalized = normalized.replace(/\s+/g, "_");
  normalized = normalized.replace(/[^a-zA-Z0-9_]/g, "");

  return normalized;
};

export { HideCategory, NormalizeCategory };

export const FilterCategory = ({onCategorySelected, onSeasonSelected}) => {
  const { selectedFilter, setSelectedFilter } = useContext(FilterContext);
  const
    [filters, setFilters] = useState([]),
    [visibleCount, setVisibleCount] = useState(0)
  ;

  const fetchFilters = async () => {
    try {
      const response = await fetchData(url_categories);
      setFilters(response.data);
      setVisibleCount(response.data.length);
    } catch(error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleFilterClick = (filter) => {
    HideCategory(prevCategories);
    setSelectedFilter(NormalizeCategory(filter.title));
    onCategorySelected(filter.id);
    setVisibleCount(count);
  };

  const handleClearFilters = () => {
    const btns = document.querySelectorAll(".filter_buttons .filter_button.visible");

    btns.forEach((btn) => {
      btn.classList.add("visible");
    });

    onCategorySelected(null);
    setVisibleCount(filters.length);
    setSelectedFilter("");

    let cells = document.querySelectorAll(".cell_kanban");

    if (cells.length > 0) {
      cells.forEach(function (element) {
        element.classList.remove("cell_filter");
      });
    }

    if (onSeasonSelected !== undefined) {
      onSeasonSelected(null);
    }
    HideCategory(prevCategories);
  }

  useEffect(() => {
    fetchFilters();
  }, []);

  return (
    <div className="filter_categories">
      <div className="filter_buttons">
        {visibleCount > 0 && (
          <>
          <button className="btn_clear_filter" onClick={handleClearFilters}>clear</button>
          {filters.map((filter) => (
            <button
              key={filter.title}
              className={`filter_button ${NormalizeCategory(filter.title)} ${selectedFilter === NormalizeCategory(filter.title) ? "active" : ""} ${document.querySelector(`.${NormalizeCategory(filter.title)}`)?.classList.contains("visible") ? "visible" : ""}`}
              onClick={() => handleFilterClick(filter)}
              style={{ backgroundColor: filter.color }}
              id={filter.id}
            >
            <p className="text">
              {filter.title}
            </p>
            </button>
          ))}
          </>
        )}
      </div>
    </div>
  );
};
