// components
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import { MenuUser } from "../../components/MenuUser/MenuUser.js";

// resources
import logo_adidas_white from "../../assets/img/svg/adidas_white.svg";
import card1 from "../../assets/img/guide/card1.webp";
import card2 from "../../assets/img/guide/card2.webp";
import card3 from "../../assets/img/guide/card3.webp";
import card4 from "../../assets/img/guide/card4.webp";
import card5 from "../../assets/img/guide/card5.webp";
import card7 from "../../assets/img/guide/card7.webp";
import card8 from "../../assets/img/guide/card8.webp";
import card9 from "../../assets/img/guide/card9.webp";
import chart from "../../assets/img/guide/Chart.webp";
import mp4 from "../../assets/videos/1.mp4";
import ogv from "../../assets/videos/1.ogv";
import webm from "../../assets/videos/1.webm";

// styles
import "../../assets/css/pages/guide.css";
const Navigation_guide = () => {

  return (
    <div className="container_guide layout_3_l">
      <NavigationBar className="a3l_1" styleType={"open"} />
      <MenuUser/>

      <div className="container_page a3l_2">
        <picture className="single_logo_adidas">
          <img src={logo_adidas_white} alt="adidas" className="img"/>
        </picture>
      <h1 className="title_guide">
        OMNI LAM SEASONAL ACTIVATION PLAN
      </h1>
      <h2 className="subtitle_guide no_visible">
        OMNI LAM SEASONAL ACTIVATION PLAN
      </h2>
        <p className="desc_guide">
          Bienvenido, en esta guía de usuario encontraras todo para gestionar óptimamente tu Omni LAM Seasonal Activation Plan.
        </p>

        <video preload="yes" className="video_guide" playsInline="" controls="on">
          <source src={webm} type="video/webm"/>
          <source src={ogv} type="video/ogv"/>
          <source src={mp4} type="video/mp4"/>

          Your browser does not support the video tag.
        </video>
        <div className="line_guide"></div>
        <p className="desc_guide">
          ¿Qué encontrarás en cada segmento?
        </p>
        <div className="container layout_1_l">
          <div className="section-title area1">
            <h3 className="title">
              CALENDAR PER CHANNEL / SEGMENTATION
            </h3>
          </div>
          <div className="step step_1 area2">
            <div className="content_text">
              <h3 className="title_step">Step 1.</h3>
              <ul className="list">
                <li><p> Start here </p></li>
              </ul>
            </div>
            <div className="image_container">
              <img src={card1} alt=""/>
            </div>
          </div>
          <div className="step step_2 img_small area3">
            <div className="content_text">
              <h3 className="title_step">Step 2.</h3>
              <ul className="list">
                <li><p> 1. Every CATEGORY will have a color code...</p></li>
                <li><p> 2. Identify the color and search in the calendar</p></li>
              </ul>
            </div>
            <div className="image_container">
              <img src={card2} alt=""/>
            </div>
          </div>
          <div className="step step-3 img_small area4">
            <div className="content_text">
              <h3 className="title_step">Step 3.</h3>
              <ul className="list">
                <li><p>1. The calendar is shown by weeks</p></li>
                <li><p>2. Identify the month and week you want to deep dive</p></li>
              </ul>
            </div>
            <div className="image_container">
              <img src={card3} alt=""/>
            </div>
          </div>
          <div className="step step-4 area5">
            <div className="content_text">
              <h3 className="title_step">Step 4.</h3>
              <ul className="list">
                <li><p> 1. In the left side, you will be able to see the different levels of campaigns within the Marketing
                  Framework.</p></li>
                <li><p> 2. Identify the campaign and level of support.</p></li>
              </ul>
            </div>
            <div className="image_container">
              <img src={card4} alt=""/>
            </div>
          </div>
          <div className="step step-5 area6">
            <div className="content_text">
              <h3 className="title_step">Step 5.</h3>
              <ul className="list">
                <li><p> 1. WHS: the calendar is created by Segmentation (Multispecialist, Generalist, Directional). Every
                  Segmentation has Brand and Commercial Driven calendar.</p></li>
                <li><p> 2. DTC: the calendar is created by BCS or OCS. Each one has a level of support according to the
                  segmentation.</p></li>
              </ul>
            </div>
            <div className="image_container">
              <img src={card5} alt=""/>
            </div>
          </div>

        </div>
        <div className="container layout_2_l">
          <div className="section-title a1">
            <h3 className="title">
              SLA PER CHANNEL / SEGMENTATION
            </h3>
          </div>
          <div className="step step_1 a2">
            <div className="content_text">
              <h3 className="title_step">Step 1.</h3>
              <ul className="list">
                <li><p>Start here</p></li>
              </ul>
            </div>
            <div className="image_container">
              <img src={chart} alt=""/>
            </div>
          </div>
          <div className="step step_2 img_small a3">
            <div className="content_text">
              <h3 className="title_step">Step 2.</h3>
              <ul className="list">
                <li><p> 1. Every CATEGORY will have a color code...</p></li>
                <li><p> 2. Identify the color and search in the calendar</p></li>
              </ul>
            </div>
            <div className="image_container">
              <img src={card2} alt=""/>
            </div>
          </div>
          <div className="step step-3 img_small a4">
            <div className="content_text">
              <h3 className="title_step">Step 3.</h3>
              <ul className="list">
                <li><p> 1. Review the touchpoint that are consider in every campaign/ commercial moment</p></li>
                <li><p> 2. This includes ISC, VM & Activation</p></li>
              </ul>
            </div>
            <div className="image_container">
              <img src={card3} alt=""/>
            </div>
          </div>
          <div className="step step-4 a5">
            <div className="content_text">
              <h3 className="title_step">Step 4.</h3>
              <ul className="list">
                <li><p> 1. In the left side, you will be able to see the different levels of campaigns within the Marketing
                  Framework.</p></li>
                <li><p> 2. Identify the campaign and level of support.</p></li>
              </ul>
            </div>
            <div className="image_container">
              <img src={card7} alt=""/>
            </div>
          </div>
          <div className="step step-5 a6">
            <div className="content_text">
              <h3 className="title_step">Step 5.</h3>
              <ul className="list">
                <li><p> 1. Identify the touchpoint per every marketing framework section.</p></li>
                <li><p> 2. According to the marketing framework section you will be able to identify what category plays in
                  what touchpoint every week.</p></li>
              </ul>
            </div>
            <div className="image_container">
              <img src={card8} alt=""/>
            </div>
          </div>
          <div className="step step-5 a7">
            <div className="content_text">
              <h3 className="title_step">Step 6.</h3>
              <ul className="list">
                <li><p>
                1. In the example, you will be able to find the different campaigns / moments that will be happening on JUNE and the recommended touchpoints when 2 or more campaigns/activations are happening at the same time.
                </p></li>
              </ul>
            </div>
            <div className="image_container">
              <img src={card9} alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navigation_guide;
