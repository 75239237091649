import { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

//styles
import "../../assets/css/components/menu_user.css";

//resources
import arrow from "../../assets/img/svg/1.svg";
import calendar from "../../assets/img/svg/5.svg";
import password from "../../assets/img/svg/3.svg";
import sesion from "../../assets/img/svg/2.svg";
import close from "../../assets/img/svg/4.svg";

export const MenuUser = () => {
  // Definición de hooks
  const
    [isOpen, setIsOpen] = useState(false)
  ;

  const
    { logout } = useContext(AuthContext),
    currentUser = JSON.parse(sessionStorage.getItem("currentUser")),
    API_URL = process.env.REACT_APP_API_URL,
    name_user = currentUser.name,
    navigate = useNavigate()
  ;

  const getInitials = (name) => {
    if (!name) return "";

    const namesArray = name.split(" ");
    if (namesArray.length === 1) {
      return namesArray[0].charAt(0).toUpperCase();
    }

    return (
      namesArray[0].charAt(0).toUpperCase() +
      namesArray[namesArray.length - 1].charAt(0).toUpperCase()
    );
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    sessionStorage.setItem("yesChangePass", "true");
    navigate("/");
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const sendLogout = (e) => {
    e.preventDefault();

    try {
      logout();
    } catch (error) {
      console.error("Failed to logout:", error);
    }
  }

  const addCampaign = (e) => {
    e.preventDefault();
    window.open(API_URL, "_blank");
  }

  const initials = getInitials(name_user);

  return (
    <div className="user_menu">
      <div className="avatar" >
        <span className="initials">{initials}</span>
      </div>

      <button
        className={`toggleButton ${isOpen ? "hide": ""}`}
        onClick={toggleMenu}
      >
        <img src={arrow} alt="toggle menu" className="img" />
      </button>

      {isOpen && (
        <div className="menu">
          <button
            className="button"
            onClick={addCampaign}
          >
            <img src={calendar} alt="calendar" className="img" />
            Agregar campaña
          </button>

          <button
            className="button"
            onClick={handleButtonClick}
          >
            <img src={password} alt="password" className="img" />
            Cambiar contraseña
          </button>

          <button
            className="button"
            onClick={sendLogout}
          >
            <img src={sesion} alt="logout" className="img" />
            Cerrar sesión
          </button>

          <button
            className="closeButton"
            onClick={toggleMenu}
          >
            <img src={close} alt="close menu" className="img" />
          </button>
        </div>
      )}
    </div>
  );
};
