import { useNavigate } from "react-router-dom";

import icon_back from "../../assets/img/svg/back.svg";
import "../../assets/css/components/back_btn.css";

const ButtonBack = ({ url = "/holding" }) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(url, { replace: true });
  };

  return (
    <button className="button_back" onClick={goBack}>
      <div className="container_icon btn_black">
        <img src={icon_back} alt="" className="img_btn"/>
      </div>
      <p className="text_button">Volver atrás</p>
    </button>
  );
}

export default ButtonBack;
