import { useState } from "react";

// styles
import "../../assets/css/components/card_snapshot.css";

// resources
import expand from "../../assets/img/svg/expand.svg";

const CardSnapShot = ({ cardsGallery, area, week_lenght, setOnExpand, setIsModalOpen }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState({});

  if (week_lenght < 4 || week_lenght > 6) {
    throw new Error("week_lenght debe estar entre 4 y 6");
  }

  const filteredCards = cardsGallery.filter(
    (card) => card.distribution_area.id === area.id
  );
  const numberOfCards = filteredCards.length;
  const numberOfEmptyDivs = week_lenght - numberOfCards;

  const handlePrevImage = (e, images, id) => {
    setCurrentImageIndex((prevIndex) => {
      const currentIndex = prevIndex[id] !== undefined ? prevIndex[id] : 0; // Asegura que el índice tenga un valor inicial
      return {
        ...prevIndex,
        [id]: currentIndex === 0 ? images.length - 1 : currentIndex - 1,
      };
    });
  };

  const handleNextImage = (e, images, id) => {
    setCurrentImageIndex((prevIndex) => ({
      ...prevIndex,
      [id]: prevIndex[id] === images.length - 1 ? 0 : (prevIndex[id] || 0) + 1,
    }));
  };

  const handleOnExpand = (img) => {
    setOnExpand(img);
    setIsModalOpen(true);
    document.querySelector(".user_menu").classList.add("hide");
  }

  return (
    <div className="cards_container_snapshot cs2">
      {filteredCards.map((card) => (
        <div className="card_snapshot active" key={card.id}>
          <div className="image_container">
            <picture className="image_placeholder">
              <img
                src={card.images[currentImageIndex[card.id] || 0].url}
                alt={card.title}
                className="image"
              />
            </picture>

            <button
              className="control_button"
              onClick={() => handleOnExpand(card.images[currentImageIndex[card.id] || 0]?.url)}
            >
              <img src={expand} alt="Expand" className="image_expand"/>
            </button>
          </div>

          <div
            className={`slider_controls ${
              card.images.length <= 1 ? "no_visible" : ""
            }`}
          >
            <button
              className="prev_button"
              onClick={(e) => handlePrevImage(e, card.images, card.id)}
            >
              <svg
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#000000"
              >
                <path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" />
              </svg>
            </button>
            <button
              className="next_button"
              onClick={(e) => handleNextImage(e, card.images, card.id)}
            >
              <svg
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#000000"
              >
                <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" />
              </svg>
            </button>
          </div>
          <h3 className="title">{card.title}</h3>
        </div>
      ))}

      {[...Array(numberOfEmptyDivs)].map((_, index) => (
        <div className="card_snapshot" key={`empty-${index}`}></div>
      ))}
    </div>
  );
};

export default CardSnapShot;

