import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

// components
import { fetchData } from "../../services/apiService";
import CardSnapShot from "../../components/CardSnapShot/CardSnapShot";
import { NormalizeCategory } from "../../components/FilterCategory/FilterCategory.js";

// styles
import "../../assets/css/components/snapshot.css";
import "../../assets/css/components/loading.css";

const SnapShot = ({ params, setOnExpand, setIsModalOpen }) => {
  const
    [isLoading, setIsLoading] = useState(true),
    [categories, setCategories] = useState([]),
    [snapShots, setSnapShots] = useState([]),
    [cardsGallery, setCardsGallery] = useState([]),
    [distributionAreas, setDistributionAreas] = useState([]),
    navigate = useNavigate()
  ;

  const fetchCategories = useCallback(async () => {
    const url_categories = "/api/list/categories";

    try {
      const response = await fetchData(url_categories);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching Categories:", error);
    }
  }, []);

  const fetchSnapShot = useCallback(async () => {
    const url_snapshot = `/api/snapshot/${params.idSegment}`;

    try {
      const response = await fetchData(url_snapshot);
      console.dir(response.data);

      if (!response.success) {
        navigate("/404");
        return;
      }

      setSnapShots(response.data);
      setCardsGallery(response.data.cards_gallery.map(card => ({ ...card })));
      setDistributionAreas(Array.from(new Set(response.data.cards_gallery.map(card => JSON.stringify(card.distribution_area)))).map(area => JSON.parse(area)));
    } catch (error) {
      console.error("Error fetching SnapShot:", error);
    } finally {
      setIsLoading(false);
    }
  }, [params.idSegment, navigate]);

  const renderSnap = () => {
    return (
      <>
      <header className="header_data_snapshot layout_3_j">
        <div className="title st1">
          {snapShots.title}
        </div>
        <div className="title_segment st2">
          WHS
        </div>
        <div className="month st3">
          {snapShots.month}
        </div>
        <div className="container_weeks st4">
          {snapShots.week_numbers.map((week, index) => (
            <div key={index} className="week">{week}</div>
          ))}
        </div>
      </header>

      <section className="campaign_snapshot">
        <header className="title_campaign layout_4_j">
          <h2 className="title cs1">
            CATEGORY ACTIVATION
          </h2>
          <h2 className="title cs2">
            CAMPAIGN
          </h2>
        </header>

        <div className="content_campaigns">
          {snapShots.campaigns && snapShots.campaigns.length > 0 && snapShots.campaigns.map((campaign, index) => (
            <div key={index} className="row_campaign layout_4_j">
              <div className="type_campaign_snapshot cs1">
                {campaign.type_campaign}
              </div>

              <div
                className="campaign_content_snapshot cs2"
                style={{ backgroundColor: campaign.category.color }}
              >
                {campaign.title}
              </div>
            </div>
          ))}
        </div>

        <div className="cards_gallery_snapshot">
          {distributionAreas.map((area, index) => (
            <div key={index} className="row_card_gallery layout_4_j">
              <div className={`distribution_area cs1 ${index % 2 === 0 ? 'even': 'odd'}`}>
                {area.name}
              </div>

              <CardSnapShot
                cardsGallery={cardsGallery}
                area={area}
                week_lenght={snapShots.week_numbers.length}
                setOnExpand={setOnExpand}
                setIsModalOpen={setIsModalOpen}
              />
            </div>
          ))}
        </div>
      </section>
      </>
    );
  };

  const renderLegends = () => {
    return (
      <div className="legends">
        <p className="description_snapshot">
          Estos son los tags para las categorías de adidas
        </p>

        <ul className="group_legends">
          {categories && categories.length > 0 && categories.map((c, index) => (
            <li key={index} className={`item_category category_${NormalizeCategory(c.title)}`}>
              <div
                className={`legends_category ${NormalizeCategory(c.title)}`}
                style={{ backgroundColor: c.color }}
                id={c.id}
              >
                {c.title}
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  useEffect(() => {
    fetchCategories();
    fetchSnapShot();
  }, [fetchCategories, fetchSnapShot]);

  if (isLoading) {
    return (
      <div className="loading">
        <div className="bouncing_loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <section className="container_snapshop">
      {renderLegends()}

      <div className="data_snapshot">
        {renderSnap()}
      </div>
    </section>
  );
}

export default SnapShot;
