import React from "react";

import "../../assets/css/components/type_campaing_card.css";

const TypeCampaingCard = ({ title, campaings, special = false }) => {
  return (
    <div className="moments_container">
      <div className="card_moments">
        <h4 className="title_card">{title}</h4>
        <div className="list_item">
          {campaings.map((campaing) => (
            <div className="item" key={campaing.id}>
              {!special && (
                <div
                  className="shape"
                  style={{ backgroundColor: campaing.category.color }}
                ></div>
              )}
              <p className="item_name">{campaing.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TypeCampaingCard;
