import { createContext, useState, useEffect, useCallback } from "react";
import { authenticateUser } from "../services/authService";
// import { logoutUser } from "../services/logoutService";
import { encryptData, decryptData } from "../utils/cryptoHelper";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const
    [triggerPasswordChange, setTriggerPasswordChange] = useState(() => () => {}),
    [isAuthenticated, setIsAuthenticated] = useState(false),
    [loading, setLoading] = useState(true),

    [errorMessage, setErrorMessage] = useState({ message: "", type: "normal", title: "" })
  ;

  const navigate = useNavigate();

  const clearMessage = () => {
    setErrorMessage({ message: "", type: "normal", title: "" });
  }

  const setTriggerChangePassword = (triggerFunction) => {
    setTriggerPasswordChange(() => triggerFunction);
  };

  const login = async (username, password) => {
    clearMessage();

    try {
      const response = await authenticateUser(username, password);
      if (response) {
        const
          { current_user, logout_token, csrf_token } = response,
          loginDate = Math.floor(Date.now() / 1000).toString(),
          dataToEncrypt = `${loginDate}:${csrf_token}`,
          passToEncrypt = `${loginDate}:${password}`,
          encryptedCsrfToken = encryptData(dataToEncrypt, loginDate),
          passEncrypt = encryptData(passToEncrypt, loginDate)
        ;

        if (response.temp_pass && response.temp_pass !== "") {
          triggerPasswordChange();
        } else {
          sessionStorage.setItem("logoutToken", logout_token);

          setIsAuthenticated(true);
          navigate("/holding");
        }

        const user = {
          uid: current_user.uid,
          roles: current_user.roles,
          name: current_user.name
        };

        sessionStorage.setItem(
          "currentUser",
          JSON.stringify(user)
        );
        sessionStorage.setItem("loginDate", loginDate);
        sessionStorage.setItem("csrfToken", encryptedCsrfToken);
        sessionStorage.setItem("userToken", passEncrypt);
      }
    } catch (error) {
      if (error.message === "Invalid credentials") {
        setErrorMessage({
          title: "Credenciales inválidas.",
          message: "Verifica usuario y contraseña.",
          type: "error"
        });
      } else {
        setErrorMessage({
          title: "Error inesperado",
          message: "Ocurrio un error posiblemente en el API",
          type: "error"
        });
      }
    }
  };

  const logout = useCallback(async () => {
    clearMessage();
    setIsAuthenticated(false);
    setLoading(false);

    // NOTE: Si se requiere sumar el logout por api descomentar esto, no borrar
    /*
    const
      storedLoginDate = sessionStorage.getItem("loginDate"),
      storedEncryptedCsrfToken = sessionStorage.getItem("csrfToken"),
      logoutToken = sessionStorage.getItem("logoutToken")
    ;

    if (storedLoginDate && storedEncryptedCsrfToken) {
      const
        decryptedData = decryptData(storedEncryptedCsrfToken, storedLoginDate),
        [loginDate, csrfToken] = decryptedData.split(":") // eslint-disable-line no-unused-vars
      ;

      try {
        await logoutUser(csrfToken, logoutToken);
      } catch (error) {
        console.error("Error during logout:", error);
      }
    }
    */

    sessionStorage.clear();
  }, []);

  useEffect(() => {
    const
      storedLoginDate = sessionStorage.getItem("loginDate"),
      storedEncryptedCsrfToken = sessionStorage.getItem("csrfToken")
    ;

    // NOTE: verificación de datos
    if (storedLoginDate && storedEncryptedCsrfToken) {
      const
        decryptedData = decryptData(storedEncryptedCsrfToken, storedLoginDate),
        [loginDate, csrfToken] = decryptedData.split(":") // eslint-disable-line no-unused-vars
      ;

      // NOTE: Verificación de fecha
      (loginDate === storedLoginDate)
        ? setIsAuthenticated(true)
        : logout();
    } else { logout(); }
    setLoading(false);
  }, [logout]);

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      loading,
      login,
      logout,
      errorMessage,
      setTriggerChangePassword
    }}>
      {children}
    </AuthContext.Provider>
  );
};

