// resources
import arrow from "../../assets/img/svg/arrow_btn_w.svg";

// styles
import "../../assets/css/components/error_message.css";

const ErrorMessage = ({ onSeasonChange, setActiveButton }) => {
  const goSeasonDefault = () => {
    const season_id = sessionStorage.getItem("season_default");

    sessionStorage.setItem("season_now", season_id);
    onSeasonChange(season_id);
    setActiveButton(season_id);
  }

  return (
    <div className="container_error_message">
      <h3 className="subtitle">
        Error
      </h3>
      <h2 className="title">
        ¡LO LAMENTAMOS!
      </h2>
      <p className="description">
        datos no encontrados
      </p>

      <button className="button_back_holding" onClick={goSeasonDefault}>
        <span>Volver al inicio</span>
        <img src={arrow} alt="toggle holding" className="img" />
      </button>

    </div>
  );
};

export default ErrorMessage;
