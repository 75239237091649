const GetLink = ({ segments }) => {
  // console.dir(segments);
  const linkMap = {
    WHS: `/whs/${segments.id}`,
    SLA: `/sla/${segments.id}`,
    Example: `/examples/${segments.id}`
  };

  return linkMap[segments.type] || null;
};

export default GetLink;
