// componentes
import { useState, useContext, useEffect, useCallback } from "react";
import { AuthContext } from "../../context/AuthContext";
import Message from "../../components/Message/Message";
import { requestPasswordRecovery } from "../../services/passwordRecoveryService";
import { requestChangePassword } from "../../services/changePasswordService";
import { decryptData } from "../../utils/cryptoHelper";

// assets
import logo_adidas_white from "../../assets/img/svg/adidas_white.svg";
import arrow from "../../assets/img/svg/arrow.svg";
import visibility from "../../assets/img/svg/visibility.svg";
import visibility_off from "../../assets/img/svg/visibility_off.svg";

// styles
import "../../assets/css/pages/login.css";

let cent_recovery = true;

const
  emailPattern = new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")
;

const Login = () => {
  // Definición de hooks
  const
    [isRecovery, setIsRecovery] = useState(false),
    [isEmailValid, setIsEmailValid] = useState(false),
    [isUserValid, setIsUserValid] = useState(false),
    [isPasswordValid, setIsPasswordValid] = useState(false),
    [isConfirmation, setIsConfirmation] = useState(false),
    [isHideRecovery, setIsHideRecovery] = useState(false),
    [isChangePassword, setIsChangePassword] = useState(false),
    [isNewPasswordValid, setIsNewPasswordValid] = useState(false),
    [isNewPasswordCValid, setIsNewPasswordCValid] = useState(false),
    [isPasswordHidden, setIsPasswordHidden] = useState(true),
    [isNewPasswordHidden, setIsNewPasswordHidden] = useState(true),
    [isNewCPasswordHidden, setIsNewCPasswordHidden] = useState(true),
    [isGreat, setIsGreat] = useState(false),

    [userInput, setUserInput] = useState(""),
    [emailValue, setEmailValue] = useState(""),
    [passwordInput, setPasswordInput] = useState(""),
    [newPasswordInput, setNewPasswordInput] = useState(""),
    [newPasswordCInput, setNewPasswordCInput] = useState("")
  ;

  const { login, errorMessage, setTriggerChangePassword } = useContext(AuthContext);

  // girar la card
  const openRecovery = (e) => {
    e.preventDefault();
    setIsRecovery(true);
    setIsGreat(false);
    setIsHideRecovery(false);
    setIsEmailValid(false);
  };

  const closeRecovery = (e) => {
    e.preventDefault();
    setIsRecovery(false);
    setPasswordInput("");
    sessionStorage.removeItem("yesChangePass");
  };

  // Hacer login
  const sendLogin = async (e) => {
    e.preventDefault();

    try {
      await login(userInput, passwordInput);
    } catch (error) {
      console.error("Failed to login:", error);
    }
  }

  // Enviar el email para el recovery
  const sendRecovery = async (e) => {
    e.preventDefault();

    if (cent_recovery) {
      cent_recovery = false;

      try {
        const email = e.target.elements[0].value;

        // llamada al servicio de recuperación
        const request_password = await requestPasswordRecovery(email);

        if (request_password.ok) {
          cent_recovery = true;
        }

        if (!isConfirmation) {
          setIsConfirmation(true);

          setTimeout(() => {
            setIsHideRecovery(true);
          }, 500)
        }
      } catch (error) {
        console.error("Error during password recovery:", error);
      }
    }
  };

  // Confirmation email for recovery
  const sendConfirmation = (e) => {

    e.preventDefault();
    setIsRecovery(false);

    setTimeout(() => {
      setEmailValue("");
      setIsConfirmation(false);
      setIsHideRecovery(false);
    }, 500);
  }

  // Definir la función para activar el cambio de contraseña
  const activateChangePassword = useCallback(() => {
    setIsRecovery(true);
    setIsChangePassword(true);
    setIsHideRecovery(true);
  }, []);

  // enter here
  const enterHere = (e) => {
    e.preventDefault();
    sendConfirmation(e);
  }

  // Enviar la nueva contraseña para cambiar
  const sendNewPassword = async (e) => {
    e.preventDefault();

    let
      userToken = null,
      nowPassword = null
    ;

    const
      storedLoginDate = sessionStorage.getItem("loginDate"),
      storedEncryptedCsrfToken = sessionStorage.getItem("csrfToken"),
      storeUserToken = sessionStorage.getItem("userToken"),
      currentUser = JSON.parse(sessionStorage.getItem("currentUser"))
    ;

    if (storedLoginDate && storedEncryptedCsrfToken) {
      const
        decryptedData = decryptData(storedEncryptedCsrfToken, storedLoginDate),
        [loginDate, csrfToken] = decryptedData.split(":") // eslint-disable-line no-unused-vars
      ;

      if (storeUserToken) {
        const
          decryptedUserToken = decryptData(storeUserToken, storedLoginDate),
          [, userTokenE] = decryptedUserToken.split(":")
        ;

        userToken = userTokenE;
        setPasswordInput(userTokenE);
      }

      nowPassword = userToken ?? passwordInput;

      /*
      console.log(storedLoginDate);
      console.log(csrfToken);
      console.dir(currentUser);
      console.dir(currentUser.name);
      console.log(userToken ?? passwordInput);
      console.log(newPasswordInput);
      */

      await requestChangePassword(
        currentUser.uid,
        csrfToken,
        nowPassword,
        newPasswordInput,
      );

      setIsGreat(true);
      setIsChangePassword(false);
    }
  }

  const returnLogin = (e) => {
    e.preventDefault();
    setIsRecovery(false);
    setPasswordInput("");
    sessionStorage.removeItem("yesChangePass");
  }

  // Verifica que el email tenga el patrón adecuado
  const handleEmailChange = (e) => {
    setEmailValue(e.target.value);
    setIsEmailValid(emailPattern.test(e.target.value));
  };

  /* login */
  const handleUserChange = (e) => {
    setUserInput(e.target.value);
    setIsUserValid(e.target.value.trim() !== "");
  };

  const handlePasswordChange = (e) => {
    setPasswordInput(e.target.value);
    setIsPasswordValid(e.target.value.trim() !== "");
  };
  /* End  login */

  /* Nuevo password */
  const handleNewPassword = (e) => {
    setNewPasswordInput(e.target.value);
    setIsNewPasswordValid(e.target.value !== "");
  };

  const handleNewPasswordConfirmation = (e) => {
    setNewPasswordCInput(e.target.value);
    setIsNewPasswordCValid(e.target.value !== "");
  };
  /* End nuevo password */

  useEffect(() => {
    setTriggerChangePassword(activateChangePassword);
  }, [setTriggerChangePassword, activateChangePassword]);

  /* Change password after logging */
  useEffect(() => {
    const
      userToken = sessionStorage.getItem("userToken"),
      yesChangePass = sessionStorage.getItem("yesChangePass")
    ;

    if (userToken && yesChangePass) {
      setIsRecovery(true);
      setIsChangePassword(true);
      setIsHideRecovery(true);
    }
  }, []);

  return (
    <div className="container_login">
      <picture className="single_logo_adidas">
        <img src={logo_adidas_white} alt="adidas" className="img" />
      </picture>

      <h1 className="title_login">
        OMNI LAM SEASONAL ACTIVATION PLAN
      </h1>
      <h2 className="subtitle_login">
        OMNI LAM SEASONAL ACTIVATION PLAN
      </h2>

      {/* card */}
      <div
        className={`container_form_login ${isRecovery ? "active" : ""}`}
      >
        {/* login */}
        <section className="access_login item_login">
          <div className="row titles">
            <h3 className="title_form_login">
              ¡Bienvenido!
            </h3>
            <p className="text_form_login">
              Por favor ingresa tus credenciales
            </p>
          </div>

          <form id="login_form" className="login_form" onSubmit={sendLogin}>
            <div className="row">
              <input
                type="text"
                placeholder="Usuario"
                className="input_login"
                tabIndex="1"
                autoComplete="off"
                value={userInput}
                onChange={handleUserChange}
              />
            </div>

            <div className="row">
              <input
                autoComplete="off"
                type={isPasswordHidden ? "password" : "text"}
                placeholder="Contraseña"
                className="input_login"
                tabIndex="2"
                value={passwordInput}
                onChange={handlePasswordChange}
              />

              <button
                type="button"
                onClick={() => setIsPasswordHidden(!isPasswordHidden)}
                className="eye_icon"
              >
                <img src={isPasswordHidden ? visibility : visibility_off} alt="eye" />
              </button>

            </div>

            <div className="row container_btn_login">
              <button
                type="submit"
                className="submit_login btn_login"
                tabIndex="3"
                disabled={!isUserValid || !isPasswordValid}
              >
                Acceder
              </button>
            </div>
          </form>

          <div className="row">
            <button
              onClick={openRecovery}
              className="reset_password"
            >
              ¿Olvidaste tu contraseña?
            </button>
          </div>
        </section>
        {/* End login */}

        {/* recovery */}
        <section className="recovery_login item_login">
          <button
            className={`back_login ${(!isConfirmation && !isChangePassword)  ? "" : "hide"}`}
            onClick={closeRecovery}
          >
            <img src={arrow} alt="Back login" className="img" />
            <p className="text_back_login">Regresar</p>
          </button>

          {/* recovery */}
          <div className={`recovery ${!isConfirmation ? "active": ""} ${isHideRecovery ? "hide": ""}`}>
            <div className="row titles">
              <h3 className="title_form_login">
                ¿Olvidaste tu contraseña?
              </h3>
              <p className="text_form_login">
                No te preocupes, por favor ingresa tu correo electrónico asociado a la cuenta
              </p>
            </div>

            <form id="recovery_form" className="recovery_form" onSubmit={sendRecovery}>
              <div className="row email_recovery">
                <input
                  className="input_login"
                  autoComplete="off"
                  type="email"
                  placeholder="Su correo"
                  tabIndex="4"
                  value={emailValue}
                  onChange={handleEmailChange}
                />
              </div>

              <div className="row container_btn_recovery">
                <button
                  className="submit_recovery btn_login"
                  type="submit"
                  tabIndex="5"
                  disabled={!isEmailValid}
                >
                  Recuperar
                </button>
              </div>
            </form>

            <div className="row">
              <p className="text_2_form_login">
                A continuación recibirá un email de validación para recuperar su contraseña.
              </p>
            </div>
          </div>
          {/* end recovery */}

          {/* confimation  */}
          <div className={`confirmation_login ${isConfirmation ? "active" : ""}`}>
            <h3 className="title_confirmation_login">
              Confirma tu correo
            </h3>
            <p className="text_confimation_login">
              Hemos enviado un email de verificación para reestablecer tu contraseña
            </p>

            <div className="row">
              <button
                className="confirmation_recovery btn_login"
                type="button"
                onClick={sendConfirmation}
              >
                Entendido
              </button>
            </div>

            <p className="text_2_confimation_login">
              ¿Ya tienes cuenta de acceso? &nbsp;

              <button
                className="enter_here"
                onClick={enterHere}
              >
                Ingresa aquí
              </button>
            </p>
          </div>
          {/* End confimation  */}

          {/* change password  */}
          <div className={`change_password ${isChangePassword ? "active" : ""}`}>
            <div className="row">
              <h3 className="title_change_password">
                Hola de nuevo
              </h3>
            </div>

            <div className="row">
              <p className="text_change_password">
                Ingresa y confirma tu nueva contraseña para cambiarla.
              </p>
            </div>

            <form id="change_password_form" className="change_password_form" onSubmit={sendNewPassword}>
              <div className="row container_change_password">
                <label htmlFor="new_password" className="label_login">
                  Nueva contraseña
                </label>
                <input
                  id="new_password"
                  className="input_login"
                  autoComplete="off"
                  type={isNewPasswordHidden ? "password" : "text"}
                  placeholder="Nueva contraseña"
                  tabIndex="6"
                  onChange={handleNewPassword}
                />
                <button
                  type="button"
                  onClick={() => setIsNewPasswordHidden(!isNewPasswordHidden)}
                  className="eye_icon"
                >
                  <img src={isNewPasswordHidden ? visibility : visibility_off} alt="eye" />
                </button>
              </div>

              <div className="row container_change_password">
                <label htmlFor="new_confirmation_password" className="label_login">
                  Confirma tu contraseña nueva
                </label>
                <input
                  id="new_confirmation_password"
                  className="input_login"
                  autoComplete="off"
                  type={isNewCPasswordHidden ? "password" : "text"}
                  placeholder="Confirma tu nueva contraseña"
                  tabIndex="7"
                  onChange={handleNewPasswordConfirmation}
                />
                <button
                  type="button"
                  onClick={() => setIsNewCPasswordHidden(!isNewCPasswordHidden)}
                  className="eye_icon"
                >
                  <img src={isNewCPasswordHidden ? visibility : visibility_off} alt="eye" />
                </button>
              </div>

              <div className="row container_btn_recovery">
                <button
                  className="submit_change_password btn_login"
                  type="submit"
                  tabIndex="8"
                  disabled={!(isNewPasswordValid && isNewPasswordCValid && newPasswordInput === newPasswordCInput)}
                >
                  Establecer
                </button>
              </div>
            </form>
          </div>
          {/* End change password  */}

          {/* great */}
          <div className={`great ${isGreat ? "active" : ""}`}>
            <div className="row">
              <h3 className="title_great">
                ¡Genial!
              </h3>
            </div>

            <div className="row">
              <p className="text_great">
                Tu contraseña ha sido reestablecida satisfactoriamente
              </p>
            </div>

            <div className="row">
              <p className="text_great text_2">
                Ya puedes usar tu nueva contraseña junto a tu usuario para acceder a adidas Omni LAM Seasonal Activation Plan
              </p>
            </div>

            <div className="row">
              <button
                className="great_btn btn_login"
                type="button"
                onClick={returnLogin}
              >
                Ingresar
              </button>
            </div>
          </div>
          {/* End great */}
        </section>
        {/* End recovery */}
      </div>
      {/* end card */}

      {/* message */}
      {errorMessage.message !== "" &&
        <Message title={errorMessage.title} message={errorMessage.message} type={errorMessage.type}/>}
      {/* end message */}
    </div>
  );
}

export default Login;
