// components
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// resources
import icon_black from "../../assets/img/svg/plus_black.svg";
import icon_white from "../../assets/img/svg/plus_white.svg";
import icon_home from "../../assets/img/svg/home_icon.svg";
import icon_menu from "../../assets/img/svg/menu_icon.svg";
import icon_bell from "../../assets/img/svg/bell_icon.svg";
import icon_close from "../../assets/img/svg/close.svg";

// styles
import "../../assets/css/components/navigation_bar.css";

const NavigationBar = ({ styleType = "open", className = "", news}) => {
  const
    [isNavOpen, setIsNavOpen] = useState(styleType === "open")
  ;

  const
    navigate = useNavigate()
  ;

  const toggleNav = () => {
    setIsNavOpen(true);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  const btnGuide = (e) => {
    e.preventDefault();
    navigate("/navigation-guide", { replace: true});
  }

  const btnSegment = (e) => {
    e.preventDefault();
    navigate("/holding", { replace: true});
  }

  return (
    <>
      <button
        className={`btn_nav ${className} ${isNavOpen ? "btn_hidden" : "show"}`}
        onClick={toggleNav}
      >
        <div className="container_icon btn_black">
          <img src={icon_home} alt="" className="img_btn"/>
        </div>
        <div className="container_icon btn_white">
          <img src={icon_menu} alt="" className="img_btn"/>
        </div>
        <div className="container_icon btn_black">
          <img src={icon_bell} alt="" className="img_btn"/>
        </div>
      </button>

      <div className={`container_nav ${className} ${styleType === "close" ? "open_by_btn" : ""} ${isNavOpen ? "show" : "hide"}`}>
        <button className="close_menu" onClick={closeNav}>
          <img className="img" src={icon_close} alt="Close"/>
        </button>

        <button
          className="btn_nav_inside btn_white"
          onClick={btnGuide}
        >
          <p className="label">GUÍA DE NAVEGACIÓN</p>
          <img src={icon_black} alt="" className="icon"/>
        </button>
        <button
            className="btn_nav_inside btn_black"
            onClick={btnSegment}
        >
          <p className="label">SEGMENTOS</p>
          <img src={icon_white} alt="" className="icon"/>
        </button>
        {news && news.length !== 0 ? (
          <div className="container_news">
            <h3 className="title_news">ÚLTIMAS MODIFICACIONES</h3>
            {news.map((item) => (
              <div key={item.id} className="group">
                <p className="name_news">{item.title}</p>
                <p className="details_new">{item.date_created}</p>
              </div>
            ))}
          </div>
        ) : (
          <div className="container_news">
            <h3 className="title_news">ÚLTIMAS MODIFICACIONES</h3>
            <div className="group">
              <p className="name_news">No tenemos modificaciones para mostrar.</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default NavigationBar;
